import React from 'react'
import {Link} from 'gatsby'

import './style.scss'

const Eyebrow = ({data}) => {
    if (data && data.hasOwnProperty('link') && data.hasOwnProperty('text')) {
        return (
            <h6><Link to={data.link}>{data.text}</Link></h6>
        )
    }

    return null;
}

const Banner = ({ title, subtitle, eyebrow }) => (
    <>
        <section id="banner" className="sub">
            <div className="center">
                <Eyebrow data={eyebrow} />
                <h1 className={subtitle ? "" : "alone"}>{title}</h1>
                <h4 className={subtitle ? "" : "empty"} dangerouslySetInnerHTML={{__html: subtitle}}></h4>
            </div>
        </section>
    </>
)

export default Banner