import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'src/components/layout'
import Banner from 'src/components/Banners/SubBanner'
import ContactForm from 'src/components/ContactForm'

const ContactPage = () => (
  <Layout>
    <Helmet>
      <title>Contact us</title>
    </Helmet>
    <Banner title="Contact us" />
    <ContactForm />
  </Layout>
)

export default ContactPage
