import React from 'react'
import Recaptcha from 'react-recaptcha'

import "./style.scss"

export default class ContactForm extends React.Component {
    constructor() {
      super();
      this.handleSubmit = this.handleSubmit.bind(this);
      this.verifyCaptcha = this.verifyCaptcha.bind(this);
      this.btnLabelDefault = "Lets start chatting";
      this.state = {
          errorsChecked: false,
          btnLabel: this.btnLabelDefault,
          btnState: true,
          submitSuccessful: false,
          errorMessages: {},
          recaptchaSiteKey: '6Lft1ZAUAAAAAEjx_L_JBgl8u7GiFfT71OMNTiM4'
      }
      this.recaptchaInstance = false;
    }

    executeCaptcha = (evt) => {
        evt.preventDefault();
        this.setState({ 
            btnLabel: "Verifying...",
            btnState: false
        });
        this.recaptchaInstance.execute();
    }

    verifyCaptcha = (response) => {
        this.handleSubmit(response);
    }
  
    handleSubmit(recaptchaToken) {
        const form = document.getElementById("contactForm");
        if (!form.checkValidity()) {
            this.setState({ errorsChecked: true });
            // eslint-disable-next-line
            let errorMessages = this.state.errorMessages = {};
            for (let f=0;f<form.elements.length;f++) {
                let field = form.elements[f];
                if (field.getAttribute("required") !== null && field.checkValidity() === false) {
                    errorMessages[field.getAttribute("name")] = field.getAttribute("data-error");
                }
            }
            this.setState({ 
                errorsMessages: errorMessages,
                btnLabel: this.btnLabelDefault,
                btnState: true
             });
            return;
        }
        this.setState({ 
            errorsChecked: false,
            btnLabel: "Sending...",
            errorsMessages: {}
        });
        const data = new FormData(form);

        const custom_fields = {
            company: "Company",
            location: "Location",
            priority: "Urgency scale",
            duedate: "Expected date of delivery"
        }

        let fields = []
        for (var field in custom_fields) {
            if (custom_fields.hasOwnProperty(field) && data.get(field) !== '') {
                fields.push({
                    "title": custom_fields[field],
                    "value": data.get(field),
                    "short": true
                })
            }
        }

        const slackBody = {
            "attachments": [
                {
                    "color": "#ABD100",
                    "author_name": data.get('name'),
                    "title": data.get('email'),
                    "title_link": "mailto:"+data.get('email')+"?subject=Website%20Form%20Fill%20-%20ARD%20Web%20Development",
                    "text": data.get('message'),
                    "fields": fields,
                    "footer": "Website form fill",
                    "footer_icon": "https://platform.slack-edge.com/img/default_application_icon.png",
                    "ts": Math.floor(new Date().getTime() / 1000) //123456789
                }
            ]
        }
      
        fetch('https://hooks.slack.com/services/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', {
            method: 'POST',
            mode: "no-cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(slackBody),
        })
        .then(response => {
            this.setState({ submitSuccessful: true });
            return response.text()
        })
        .then(data => {console.log("Data:", data)});
    }
  
    render() {
        const { errorsChecked, btnLabel, btnState, submitSuccessful, errorMessages } = this.state;
        return (
            <section id="contact" className={`${this.props.classes || null}`}>
                <div className="wrap">
                    <h2>{this.props.headline || "Lets chat about how the ARD Service can help you and your business."}</h2>
                    <div className="formSurround">
                        <form id="contactForm" className={`${errorsChecked ? 'displayErrors' : ''} ${submitSuccessful ? 'hide' : ''}`} onSubmit={this.executeCaptcha} noValidate>
                            <div className="field half">
                                <label htmlFor="name">Your full name</label>
                                <input id="name" name="name" type="text" required data-error="Please enter a name" />
                                <span className={`error ${errorMessages.name ? 'show' : ''}`}>{`${errorMessages.name ? errorMessages.name : ''}`}</span>
                            </div>
                            
                            <div className="field half">
                                <label htmlFor="email">Your email address</label>
                                <input id="email" name="email" type="email" required data-error="Please enter a vaild email address" />
                                <span className={`error ${errorMessages.email ? 'show' : ''}`}>{`${errorMessages.email ? errorMessages.email : ''}`}</span>
                            </div>

                            <div className="field">
                                <label htmlFor="message">Tell us a little about your project/situation</label>
                                <textarea id="message" name="message" required data-error="Please enter some detail about how we can help"></textarea>
                                <span className={`error ${errorMessages.message ? 'show' : ''}`}>{`${errorMessages.message ? errorMessages.message : ''}`}</span>
                            </div>

                            <div className="field half">
                                <label htmlFor="company">Your company name</label>
                                <input id="company" name="company" type="text" />
                            </div>

                            <div className="field half">
                                <label htmlFor="location">Your location</label>
                                <input id="location" name="location" type="text" />
                            </div>

                            <div className="field half">
                                <label htmlFor="priority">Urgency scale</label>
                                <select id="priority" name="priority">
                                    <option value="">Select...</option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                </select>
                            </div>
                    
                            <div className="field half">
                                <label htmlFor="duedate">Expected date of delivery</label>
                                <input id="duedate" name="duedate" type="text" />
                            </div>
                    
                            <button disabled={!btnState ? "disabled" : ""}>{`${btnLabel}`}</button>
                            <Recaptcha
                                sitekey={this.state.recaptchaSiteKey}
                                size="invisible"
                                verifyCallback={this.verifyCaptcha}
                                ref={e => this.recaptchaInstance = e}
                            />
                        </form>
                        <div className={`successMessage ${submitSuccessful ? 'show' : ''}`}>
                            <div>
                                <h4>Look forward to chatting with you.</h4>
                                <p>We'll be in touch shortly via email to discuss with you further and potentially organise a meeting to go through things in more detail.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}